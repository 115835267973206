import React, { useState, useEffect  } from "react"
import { Link, graphql } from "gatsby"
import { Index } from "lunr"
import SEO from '../components/primary/SEO'
import styled from 'styled-components'
import Layout from '../components/primary/Layout'
import AnswerBar from '../components/page-sections/AnswerBar'
import Card from '../components/elements/CardWithTitle'


export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    LunrIndex
  }
`

const SearchResults = ({ data, location }) => {
  const params = new URLSearchParams(location.search.slice(1))
  const q = params.get("q") || ""

  const { store } = data.LunrIndex

  const index = Index.load(data.LunrIndex.index)
  let results = []
  try {
    results = index.search(q).map(({ ref }) => {
      
      return {
        uid: ref,
        ...store[ref],
      }
    })
  } catch (error) {
    console.log(error)
  }

  return (
    <Layout search>
      <SEO
        title="Search Results"
        description="Search results page"
      />
      <AnswerWrap>
        <AnswerBar initialQuery={q}/>
      </AnswerWrap>
      
      <Background>
        {results?.length > 0 ? (
          results.map(node => (
            <Card
              key={node?.uid}
              color={"red"}
              title={node?.title}
              link={`/stories/${node?.uid}`}
            />
          ))
        ) : (
          <NoArticles>No articles match that search!</NoArticles>
        )}
        {/* numPages > 1 && (
          <Pagination
            numPages={numPages}
            currentPage={currentPage}
            currentSlug={`/${subcategory.uid}`}
            color={color}
          />
        )*/}
      </Background>
    </Layout>
  )
}

export default SearchResults

const Background = styled.div`
  background-color: #fafafa;
  padding: 10px 0;
  @media (min-width: ${({theme}) => theme.breakpoints.xs}) {
    padding: 50px 0;
  }
`

const NoArticles = styled.h4`
  margin: 30px auto;
  width: 90%;
  text-align: center;
`

const AnswerWrap = styled.div`
  background-color: ${({ theme }) => theme.colors.whyMagenta};
  width: 100%;
  margin: 0;
  
  padding: 25px 10px 35px;
  > div {
    width: 100%;
  }
  @media (min-width: ${({theme}) => theme.breakpoints.s}) {
     padding: 105px 20px;
     height: 300px;
    > div {
      width: 90%;
      max-width: 1000px;
      margin: 0 auto;
    }
  }
`